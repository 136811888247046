import { get, post } from '../HttpMiddlewares/methodsService';

const generatePath = () => {
  const prod = 'https://app.kriptonmarket.com';
  const staging = 'https://staging-app.kriptonmarket.com';
  if (
    process.env.REACT_APP_ENVIRONMENT === 'staging' ||
    process.env.NODE_ENV === 'development'
  ) {
      return staging; // uncomment this when the error will be fix
  }
  return prod;
};

const BASE_URL = generatePath();

export const getCurrencies = async(getValues = {}) => {
	const apiUrl = `${BASE_URL}/public/currencies`;
	return await get(apiUrl, getValues);
}

export const getInfo = async(getValues = {}) => {
	const apiUrl = `${BASE_URL}/pay/networks`;
	return await get(apiUrl, getValues);
}

export const createPay = async(data, token) => {
	const apiUrl = `${BASE_URL}/pay/payments`;
	return await post(apiUrl, data, token);
}

export const getQuotation = async(data, token) => {
	const apiUrl = `${BASE_URL}/pay/quotation`;
	return await get(apiUrl, data, token);
}

export const getConfig = async(getValues = {}, token) => {
  if (token) {
    const apiUrl = `${BASE_URL}/pay/settings`;
	  return await get(apiUrl, getValues, token);
  }
}

export const getInvoice = async(code, token) => {
  if (code) {
    const apiUrl = `${BASE_URL}/pay/invoices/${code}`;
	  return await get(apiUrl, {}, token);
  }

}

export const makeRequestTogetStatus = async(code, token) => {
  if (code) {
	  const apiUrl = `${BASE_URL}/pay/invoices/${code}/status`;
	  return await get(apiUrl, {}, token);
  }
}

export const saveHashId = async(hash, token) => {
  if (hash) {
	  const apiUrl = `${BASE_URL}/pay/invoices/${token}`;
	  return await post(apiUrl, { tx_hash: hash });
  }
}

export const loginServices = async (username, password) => {
  const apiUrl = `${BASE_URL}/public/user/login`;

  return await post(apiUrl, {  username, password, from: 'services' });
}

export const getSummaryByTokens = async (token) => {
  const apiUrl = `${BASE_URL}/internal/v1/merchants/movements/summary_by_tokens`;

  return await get(apiUrl, {}, token);
}

export const createReward = async (amount, currencyId, invoiceToken, token) => {
  const apiUrl = `${BASE_URL}/internal/clients/rewards`;

  return await post(apiUrl, {
    amount,
    currency_id: currencyId,
    invoice_token: invoiceToken
  }, token);
}

export const getPaymentLinkInfoRequest = async(paymentLinkToken, integrationToken) => {
  if (paymentLinkToken) {
    const apiUrl = `${BASE_URL}/pay/payment_links/${paymentLinkToken}`;
	  return await get(apiUrl, {}, integrationToken);
  }
}

export const createPaymentLink = async(paymentLinkToken, integrationToken, data) => {
	const apiUrl = `${BASE_URL}/pay/payment_links/${paymentLinkToken}`;
	return await post(apiUrl, data, integrationToken);
}


export const getModalSuccessMessage = async(token) => {
	const apiUrl = `${BASE_URL}/pay/show_summary_success/${token}`;
	return await get(apiUrl, {});
}

export const checkCPF = async (cpf) =>  {
  const apiUrl = `${BASE_URL}/pay/check_cpf`;
  return await post(apiUrl, { cpf });
}